<template>
  <router-view v-if="loadingComplete"></router-view>
  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12 min-vh-100">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>

<script>
import { mapGetters } from "vuex";
import { POPULATE_PROFILE } from "@/core/services/store/project/project_profile.module";
export default {
  name: "Community",
  components: {},

  directives: {},

  data() {
    return {
      computedUserName: " ••• ",
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  mounted() {
    this.fetchUserActivityInfo();
  },

  watch: {
    userId: function() {
      this.pageLoader.componentsCompleted = 0;
      this.pageLoader.componentsRequired = 1;
      this.fetchUserActivityInfo();
    },
    computedUserName: {
      handler(val) {
        document.title =
          (val !== undefined ? val : " ••• ") + " | Input | Together";
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    fetchUserActivityInfo() {
      let payload = {
        projectProfileId: this.userId,
        params: {
          activity: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_PROFILE, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.computedUserName = this.profileActivityResponses[0].user.display_name;
        })
        .catch(err => {
          this.$log.error(err);
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the page info list, please check back later or contact the helpdesk";
        });
    }
  },

  computed: {
    ...mapGetters(["currentUser", "profileActivityResponses"]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    },
    userId: function() {
      return this.$route.params.id;
    }
  }
};
</script>
